import { NgSwitch, NgSwitchCase } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  HostBinding,
  inject,
  input,
  Input,
  OnChanges,
} from '@angular/core';
import { CrmTranslateService } from 'common-module/translate';
import { CrmTranslateMessage } from 'common-module/translate';
import {
  NzTypographyComponent,
  NzTypographyModule,
} from 'ng-zorro-antd/typography';

import { emptyValuePlaceholder } from '~/constants/common';

@Component({
  standalone: true,
  selector: 'app-title',
  template: `
    <ng-container [ngSwitch]="level">
      <h1
        nz-typography
        [nzType]="type"
        *ngSwitchCase="'1'"
        [nzContent]="visibleText()"
      ></h1>
      <h2
        nz-typography
        [nzType]="type"
        *ngSwitchCase="'2'"
        [nzContent]="visibleText()"
      ></h2>
      <h3
        nz-typography
        [nzType]="type"
        *ngSwitchCase="'3'"
        [nzContent]="visibleText()"
      ></h3>
      <h4
        nz-typography
        [nzType]="type"
        *ngSwitchCase="'4'"
        [nzContent]="visibleText()"
      ></h4>
      <h5
        nz-typography
        [nzType]="type"
        *ngSwitchCase="'5'"
        [nzContent]="visibleText()"
      ></h5>
      <h6
        nz-typography
        [nzType]="type"
        *ngSwitchCase="'6'"
        [nzContent]="visibleText()"
      ></h6>
    </ng-container>
  `,
  imports: [NgSwitch, NgSwitchCase, NzTypographyModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TitleComponent implements OnChanges {
  @Input() level: '1' | '2' | '3' | '4' | '5' | '6' = '3';
  @Input() weight!: 'regular' | 'medium' | 'bold';
  @Input() type!: NzTypographyComponent['nzType'];
  @Input() margin: '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' = '0';
  message = input<CrmTranslateMessage>();
  text = input<string>();

  @HostBinding('class') protected hostClass!: string;

  protected visibleText = computed(() => {
    const msg = this.message();

    if (msg) {
      return this.translate.get(msg);
    }

    return this.text() ?? emptyValuePlaceholder;
  });

  private readonly translate = inject(CrmTranslateService);

  ngOnChanges() {
    const baseClass = 'ehr-title';

    this.initWeight();

    this.hostClass = [
      baseClass,
      `${baseClass}--weight-${this.weight}`,
      `${baseClass}--margin-${this.margin}`,
    ].join(' ');
  }

  private initWeight() {
    if (this.weight) {
      return;
    }

    switch (this.level) {
      case '1':
      case '2':
        this.weight = 'bold';
        break;
      default:
        this.weight = 'regular';
        break;
    }
  }
}
